<template>
    <div>
        <b-overlay
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            spinner-mediam
        >
            <transition
                :name="$store.state.appConfig.layout.routerTransition"
                mode="out-in"
            >
                <b-row>
                    <b-col md="9">
                        <b-card no-body>
                            <b-card-body class="">
                                <p>تساعد هذه الأداة على تذكير عملاءك بالعودة لإكمال عملية الشراء ، وتشجيعهم باستخدام كوبون خصم. يتم إرسال التذكير كل ساعة كرسالة SMS. ويتم تذكير العميل مرة واحدة فقط.</p>
                                <div class="text-center">
                                    <img src="https://cdn.mhd.sa/asset/images/abandoned_cart.png" width="50%" />
                                </div>
                                <b-form @submit.prevent="update">

                                    <!-- Coupone -->
                                    <b-col cols="12">
                                        <b-form-group
                                            :label="$t('The Product')"
                                            label-for="v-product"
                                        >
                                            <v-select
                                                v-model="abandoned_cart.coupon"
                                                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                                                label="code"
                                                :options="coupons"
                                            >
                                                <template #option="{ title, code }">
                                                    <span> {{ title }} - {{ code }}</span>
                                                </template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>

                                    <!-- Is Active -->
                                    <b-col cols="12">
                                        <b-form-group
                                        :label="$t('Is Active ?')"
                                        label-for="v-is-active"
                                        >
                                            <b-form-checkbox
                                                id="v-is-active"
                                                class="custom-control-primary"
                                                v-model="abandoned_cart.is_active"
                                                name="is_active"
                                                switch
                                            >
                                                <span class="switch-icon-left">
                                                    <feather-icon icon="CheckIcon" />
                                                </span>
                                                <span class="switch-icon-right">
                                                    <feather-icon icon="XIcon" />
                                                </span>
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </b-col>

                                    <!-- submit -->
                                    <b-col cols="12">
                                        <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        type="submit"
                                        variant="primary"
                                        class="mr-1 mt-1"
                                        >
                                            <template v-if="!isLoadingSave">
                                                {{$t('Save')}}
                                            </template>
                                            <template v-if="isLoadingSave">
                                                {{$t('Saveing')}}...
                                                <b-spinner small />
                                            </template>
                                        </b-button>
                                    </b-col>
                                </b-form>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </transition>
        </b-overlay>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
export default {
    directives: {
        Ripple,
    },
    mounted() {
        this.getCoupons().then(() => {
            this.getResults()
        });
    },
    data() {
        return {
            abandoned_cart: {},
            isLoading: true,
            isLoadingSave: false,
            coupons: []
        }
    },
    methods: {
        getResults() {
            useJwt.post('/store/config/get-by-id',{key: 'abandoned_cart'})
            .then((response) => {
                // console.log(response.data)
                if (response.data.data && Object.keys(response.data.data).length) {
                    this.abandoned_cart = response.data.data.abandoned_cart
                    this.abandoned_cart.is_active =  this.abandoned_cart.is_active ? true : false
                    this.abandoned_cart.coupon = this.coupons.find(coupon => { return coupon.id == this.abandoned_cart.coupon_id })
                }
                this.isLoading = false
            })
            .catch(response => {
                console.log(response);
            });
        },
        update() {
            this.isLoadingSave = true;
            if (this.abandoned_cart.coupon) {
                this.abandoned_cart.coupon_id = this.abandoned_cart.coupon.id
            } else {
                this.abandoned_cart.coupon_id = null
            }
            
            useJwt.post('/marketing-offers/abandoned-cart/save',this.abandoned_cart)
            .then((response) => {
                // console.log(response.data);
                this.isLoadingSave = false
                this.$root.showToast('success', 'save', '')
                // this.abandoned_cart = response.data.data;
            })
            .catch(error => {
                // console.log(response);
                this.isLoadingSave = false
                this.$root.showAlert('error', error.response.data.message)
            });
        },
        async getCoupons(){
            await useJwt.get('/coupons/get-all')
            .then((response) => {
                // console.log(response.data)
                this.coupons = response.data.data.data
            })
            .catch(response => {
                // console.log(response);
            });
        },
    },
}
</script>

<style lang="sass" scoped>

</style>
